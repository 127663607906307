import axios from "axios";
import store from "../../../../../redux/store";
import moment from "moment-timezone";

const configurationData = {
  supported_resolutions: ["1", "5"],
};

let currentSub;
let lastHistoricalData;

export default {
  onReady: (callback) => {
    setTimeout(() => callback(configurationData));
  },
  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback
    // onErrorCallback,
  ) => {
    console.log(
      "[getBars] Method call",
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback
    );

    try {
      const currentDay = moment().day();
      // const currentDay = 0;

      let from, to;
      const { firstDataRequest } = periodParams;

      if ((currentDay === 6 || currentDay === 0) && firstDataRequest) {
        from = moment(periodParams.from * 1000)
          .tz("UTC")
          .day(-3)
          .format("YYYY-MM-DD-HH:mm");
        to = moment(periodParams.to * 1000)
          .tz("UTC")
          .day(-2)
          .subtract(1, "minute")
          .format("YYYY-MM-DD-HH:mm");
      } else {
        from = moment(periodParams.from * 1000)
          .tz("UTC")
          .format("YYYY-MM-DD-HH:mm");
        to = moment(periodParams.to * 1000).tz("UTC");

        if (firstDataRequest) {
          to.subtract(1, "minute");
        }

        to = to.format("YYYY-MM-DD-HH:mm");
      }

      const resp = await axios.get(
        `https://marketdata.tradermade.com/api/v1/timeseries?currency=${symbolInfo.ticker}&api_key=QNvOskC2kxVQNfxi6jlS&start_date=${from}&end_date=${to}&format=records&period=${resolution}&interval=minute`
      );

      const data = resp.data.quotes.map((obj) => {
        return {
          time: moment(obj.date).add(3, "hours").valueOf(),
          ...obj,
        };
      });

      if (data.length < 1) {
        onHistoryCallback([], { noData: true });
      } else {
        onHistoryCallback(data, { noData: false });
        lastHistoricalData = data[data.length - 1];
      }
    } catch (e) {
      onHistoryCallback([], { noData: true });
    }
  },
  resolveSymbol: async (symbolName, onSymbolResolvedCallback) => {
    console.log("resolveSymbol");
    const state = store.getState();

    const pricescale = Number(state.trading.pair.pricescale)
      ? Number(state.trading.pair.pricescale)
      : 1;

    console.log(pricescale, "pricescale", state.trading.pair.pricescale);

    const symbol = {
      ticker: symbolName,
      session: "24x7",
      // timezone: "Etc/UTC",
      minmov: 1,
      pricescale,
      has_intraday: true,
      // intraday_multipliers: ["1", "5"],
      // has_empty_bars: true,
      has_weekly_and_monthly: false,
      // supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 1,
      data_status: "streaming",
    };

    onSymbolResolvedCallback(symbol);
  },
  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, id) => {
    console.log(
      "[subscribeBars]: Method call with subscribeUID:",
      symbolInfo,
      resolution
    );

    const newSub = {
      id,
      symbolInfo,
    };

    currentSub = newSub;

    let timer = null;

    let isFirsUpdate = true;

    window.Echo.channel(`SYMBOL.${symbolInfo.ticker}`).listen(
      "ChartUpdate",
      (e) => {
        clearTimeout(timer);
        console.log("ChartUpdate", currentSub.id, id);
        const realTimeData = {
          time: e.time,
          open: e.open,
          high: e.high,
          low: e.low,
          close: e.close,
        };

        if (isFirsUpdate) {
          lastHistoricalData.time = realTimeData.time - 1000;
          onRealtimeCallback(lastHistoricalData);
          isFirsUpdate = false;
        }

        timer = setTimeout(() => {
          if (currentSub.id === id) {
            onRealtimeCallback(realTimeData);
          }
        }, 500);
      }
    );
  },

  unsubscribeBars: (id) => {
    if (currentSub.symbolInfo.ticker === id.split("_")[0]) return;

    window.Echo.leave(`SYMBOL.${id.split("_")[0]}`);
  },
};
